<template>
  <div class="mikroblog-item"><a v-bind:name="'item-'+(model.original_id ? model.original_id : model.id)"></a>

    <!--
    <div class="logo-a-nadpisy shared-header" v-if="model.sharer_name">
      <img class="logo" v-bind:src="model.sharer_logo"/>

      <span class="date">{{formatDatum(model.shared_at)}}</span>
      <a v-bind:href="sharer_url_murl_by_width" class="firmaname">{{model.sharer_name}}</a><br>
      <i class="fas fa-retweet"></i> {{$t('b2bein.sdilel-pr-od')}} <i>{{model.cn}}</i>
      <div class="clearfix"></div>
    </div>
-->

    <div class="logo-a-nadpisy">
      <img class="logo" v-bind:src="model.logo"/>
      <span class="date">{{model.created}}</span>
      <a v-bind:href="url_murl_by_width" class="firmaname">{{model.cn}}</a><br>
      <b>{{model.title}}</b>

      <div class="d-flex float-right">
        <div class="about-konfirm" v-if="minun || konfirmator">
          <div :class="'semafor confirm-'+model.cf">
            <b class="c1"></b>
            <b class="c2"></b>
            <b class="c3"></b>
          </div>
        </div>

        <button type="button" class="btn mce-colorbtn-trans btn-sm " v-if="minun" v-on:click="edit" :title="$t('b2bein.upravit')"><i class="fas fa-cog"></i></button>
      </div>

    </div>

    <div class="clearfix"></div>
    <div class="content-wrapper">
      <div v-if="kraceno">{{shortmes}} <a v-on:click="zobrazitVic" class="vice">Zobrazit víc</a></div>
      <div v-html="model.message"  class="mikroblog-content" v-if="!kraceno"></div>
      <div v-html="transformedLink" class='mt-1' v-if="model.link"></div>
    </div>

    <div class="mikroblog-images-kontejner"   >
      <div class="mikroblog-images">
        <div class="images-row" v-bind:id="'imagerow_'+model.id"  >
          <figure v-for="(img, index) in model.attachments" :key="index" class="d-flex" v-touch:swipe.left="dalsi" v-touch:swipe.right="predchozi">

            <div  @click="detailObrazku(index)" v-if="!img.status" :class="'mikroblog-image cursor-pointer '+hwimagetyp(img)" v-bind:style="'background-image: url(\'' + img.small +'\')'"></div>

            <div v-if="img.status==1">
              <div  class="mikroblog-image video-converting" v-bind:style="'background-image: url(\'' + img.small +'\')'"><p>{{$t('b2bein.video-se-konvertuje')}}</p></div>
            </div>

            <video :ref="`videoPlayer_${img.id}`" :id="`hls-player-${img.id}`"  controls v-if="img.playlist &&  img.status>1"
                   class="mt-auto mb-auto video-js vjs-default-skin"
                   :poster="img.small"  >

              <source type="application/x-mpegURL" :src="img.playlist">
              Your browser does not support the video tag.
            </video>
            <!--video v-if="img.playlist &&  img.status>1" :ref="`videoPlayer_${img.id}`" class="video-js"></video-->

          </figure>
          <div class="clearfix"></div>

        </div>

      </div>
      <a class="navigace left" @click="predchozi()" v-if="current_index>0"><i class="fas fa-chevron-left"></i></a>
      <a class="navigace right" @click="dalsi()" v-if="current_index+1<model.attachments.length"><i class="fas fa-chevron-right"></i></a>
    </div>

    <div class="d-flex">


      <div v-bind:class="'item-rating d-flex '+(model.attachments.length>1 ? 's-prepinacem':'bez-prepinace')">

        <a v-bind:class="'simple-like cursor-pointer '+ (model.my_rating ? 'ratoval':'neratoval')" @click="ratingSimpleLike()"><i class="far fa-thumbs-up"></i></a>

        <span v-text="model.rating_sum" class="suma" v-if="model.rating_sum>0"></span>

      </div>

      <div class="bodova-navigace" v-if="model.attachments.length>1">
        <span v-for="(img, index) in model.attachments" :key="index" @click="presunKObrazku(index, model.id)"><img class='mikroimg' v-bind:src="img.micro" /></span>
      </div>

      <!--<% if user_signed_in? %>-->




<!--
      <div class="ml-auto tl-sdilet">
        <div class="dropdown" v-if="!minun && model.my_sharing_count==0">
          <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
            {{$t('b2bein.sdilet')}}
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="shareThis(model,2)">{{$t("b2bein.sdilet-verejne")}}</a>
            <a class="dropdown-item" @click="shareThis(model,1)">{{$t("b2bein.sdilet-s-prateli")}}</a>
            <a class="dropdown-item" @click="shareThis(model,3)">{{$t("b2bein.sdilet-s-jednim" )}}</a>
          </div>
        </div>
      </div>
-->

      <div class="komenty" v-if="!model.item_type || model.item_type<1"><a @click="showKomentDialog(model)" title="$t('Komentovat')">
        <img src="/img/mikroblog/komnety18.png">{{ $t('b2bein.Komentovat') }}</a></div>

      <div class="komenty" v-if="!minun && model.item_type==1"><a @click="napsat_zpravu()" title="$t('crm.zprava')">
        <img src="/img/mikroblog/komnety18.png">{{$t('b2bein.crm.zprava')}}</a></div>

      <div class="komenty" v-if="!minun && model.item_type==1"><a @click="napsat_nabidku()" title="$t('vloz-nabidku')">
        <img src="/img/mikroblog/komnety18.png">{{$t('b2bein.vloz-nabidku')}}</a></div>

     <!-- <% end %>      -->

    </div>

    <div v-if="model.comments.length>0 || model.shared_count" class="komenty_sdileni_pocty">
      <span class='cursor-pointer' v-if="model.comments.length>0" @click="model.show_comments = !model.show_comments">{{model.comments.length}} {{$t('b2bein.komentare')}}</span>
      <span v-if="model.shared_count>0">{{model.shared_count}} {{$t('b2bein.crm.sdileni')}}</span>
    </div>



    <div class="alert alert-success" role="alert" v-if="model.alert">
      {{ model.alert }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>


    <div v-if="model.comments.length>0 && model.show_comments" class="mikroblok-comments">
      <div class="nadpis">{{$t('b2bein.komentare')}}</div>
      <div v-for="k in model.comments" :key="k.id"  class="d-flex koment-item">
        <div class="mr-2 pt-1"><img class="komentlogo" v-bind:src="k.logo"/></div>
        <div>
          <span class='datum' v-html="formatDatum(k.created_at)"></span><br>
          {{ k.message }}</div>
      </div>

    </div>

    <div v-if="konfirmator" class="p-1 text-center konfirmator">
      <button class="btn btn-danger mr-1" @click="updateConfirmation(10)">{{$t('b2bein.admin.zamitnout')}} </button>
      <button class="btn btn-success" @click="updateConfirmation(1)">{{$t('b2bein.potvrdit')}} </button>
    </div>


  </div>
</template>

<script>

import videojs from 'video.js';

import userMixin from "@/mixins/userMixin";

const moment = require('moment');

require('moment/locale/cs');
moment.locale('cs');
import Vue from 'vue/dist/vue.esm';
import axios from "axios";
import jQuery from 'jquery';
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)
//video.js/dist/video-js.css
require('video.js/dist/video-js.min.css')

export default {
  name: "mikroblog-item",
  mixins: [userMixin],
  props: {
    model: Object,
    ikey:{
      type: String,
      default: ''
    }
  },
  data:
      function ()
      {
        return {
          aktualni:false,
          mi: this.model,
          roz: false,
          current_index: 0,
          window_width:0,
          videos:[],
          h2:0,

        }
      },
  components:{

  },
  computed: {
    me() {
      if (this.$store.getters.me){
        return this.$store.getters.me;
      }
      else
        return {}
    },

    sharer_url_murl_by_width:function(){

      if (this.window_width> 660){
        return this.model.sharer_url
      }else{
        return this.model.sharer_murl
      }
    },

    url_murl_by_width: function(){

      if (this.window_width> 660){
        return this.model.url
      }else{
        return this.model.murl
      }
    },
    konfirmator: function() {
      if (this.$parent)
        return this.$parent.konfirmator
      return false
    },

    minun: function(){
      //console.log('kont',this.me.b2_id, this.model.uid )
      return this.me.id == this.model.uid

    },
    shortmes: function(){
      return this.clearMessage().substring(0, 150);
    },
    kraceno: function(){
      return (this.potrebaProdlouzeni() && !this.roz)
    },
    extended: function(){
      var t= this.model.message
      var regex = /https:\/\/youtu\.be\/([a-zA-Z0-9_]+)/gi;
      var match = regex.exec(t);
      var extra='';
      var krm='';



      // console.log("EXT", match)
      if (match) {
        var key =match[1]
        krm= match[0]
        extra = "<div class='embedvideo'><iframe " +
            "src=\"https://www.youtube.com/embed/"+key+"\" " +
            "frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe></div>"
      }


      //https://www.youtube.com/watch?v=fRXzbYTIdJw
      t = t.replace(krm, '') + " "+extra

      regex = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_]+)/gi;
      match = regex.exec(t);
      extra='';
      krm='';

      if (match) {
        key =match[1]
        krm= match[0]
        extra = "<div class='embedvideo'><iframe " +
            "src=\"https://www.youtube.com/embed/"+key+"\" " +
            "frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe></div>"
      }

      t = t.replace(krm, '') + " "+extra

      return t

    },

    transformedLink: function(){
      var linka= this.model.link.trim()

      var key;


      var regex = /https:\/\/youtu\.be\/([a-zA-Z0-9_]+)/gi;
      var match = regex.exec(linka);
      if (match) {
        key =match[1]
        return "<div class='embedvideo'><iframe " +
            "src=\"https://www.youtube.com/embed/"+key+"\" " +
            "frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe></div>"
      }

      regex = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_]+)/gi;
      match = regex.exec(linka);

      if (match) {
        key =match[1]
        return  "<div class='embedvideo'><iframe " +
            "src=\"https://www.youtube.com/embed/"+key+"\" " +
            "frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe></div>"
      }

      regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
      match = regex.exec(linka);
      if (match) {
        //console.log('lm', match)
        return "<a href=\""+linka+"\" target='_blank'>"+linka+"</a>"
      }

      return linka;
    },


  },
  methods: {
    hwimagetyp:function(img){
      if (img.w && img.h){
        if (img.w > img.h) return 'horizontal-image'
        if (img.w < img.h) return 'vertical-image'
      }
    },
    ratingSimpleLike:function(){
      const config = {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }
      var that = this.$parent

      axios.post(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/ratuj', {mikroblog: this.model.id, val: 1 }, config)
          .then(response => {
            var d = response.data
            for(var i in that.dposts){
              if (that.dposts[i].id==this.model.id){
                that.$set(that.dposts[i], 'rating_sum',d.count)
                that.$set(that.dposts[i], 'my_rating',1)
              }
            }
            //$('#modal-mikroblog').modal('hide');
          });
    },

    shareThis:function(model,level){
      //console.log('sdilka', model.id, level)
      if (level==1  || level==2){
        this.$parent._sendShare(model, level);
      }else{
        this.$parent.komented_item = model
        jQuery('#modal-mikroblog-share').modal({});
      }

    },
    formatDatum:function(d){
      var a = moment(d);
      //return a.format(format ? format : "LLL")
      return a.fromNow();
    },
    ratingChange: function(){
      console.log('RAT', this.model.id)
    },

    showKomentDialog:function(model){
      this.$parent.showKomentDialog(model)
    },
    clearMessage:function(){
      if (!this.model.message) return "";
      //return new String(this.model.message).replace("\\<.*?>","");
      let regex = /(<([^>]+)>)/ig;
      return this.model.message.replace(regex, " ");
    },


    potrebaProdlouzeni:function(){

      var t1=this.clearMessage()
      var t2=this.clearMessage().substring(0, 150);
      //console.log('krr', t1.length, t2.length, t2)
      return (t1.length > t2.length);
    },
    zobrazitVic:function(){

      this.roz=true


    },
    edit:function(){
      this.$parent.edt(this.model.id)
    },

    detailObrazku:function(index){

      this.$parent.showLightbox(this.model.attachments, index)

    },

    predchozi:function(){
      if (this.current_index>0) {
        this.current_index--
        var w = jQuery('#imagerow_' + this.model.id+" figure").width()
        var l = this.current_index * w
        jQuery('#imagerow_' + this.model.id).parent().animate({scrollLeft: l}, 500);
      }
      this.aktualisovatVyskuFigure();
    },
    dalsi: function(){
      //  console.log("je "+this.current_index+1 , " < ", this.model.attachments.length)
      if (this.current_index+1<this.model.attachments.length) {
        this.current_index++
        var w = jQuery('#imagerow_' + this.model.id+" figure").width()
        var l = this.current_index * w
        jQuery('#imagerow_' + this.model.id).parent().animate({scrollLeft: l}, 500);
      }
      this.aktualisovatVyskuFigure();
    },

    presunKObrazku:function (index, el){
      this.current_index =index
      var w = jQuery('#imagerow_' + el+" figure").width()
      var l= index*w
      //console.log('posuv', index, el,l, $('#imagerow_'+el))
      jQuery('#imagerow_'+el).parent().animate({scrollLeft: l}, 500);
      this.aktualisovatVyskuFigure();

    },
    aktualisovatVyskuFigure:function(){
      //console.log(' aktualisuj vf', this.model.key, this.aktualni)
      //if (this.aktualni) return false


      var width = jQuery('.mikroblog-images').width()
      var h2




      if (this.model.attachments[0]) {




        //let att0 = this.model.attachments[0]
        var i = this.current_index ? this.current_index : 0

        let att_i = this.model.attachments[i]
        //for (var i in this.model.attachments){
        var rat = this.model.attachments[i].w / this.model.attachments[i].h
        //console.log('width', width,  rat)
        //var wr = width/this.model.attachments[i].w

        if (rat < 1) {
          h2 = width
       //   w2 = width * rat
        } else {
       //   w2 = width
          h2 = width / rat
        }

        /*
                if (wr<1) {

                  h2 = this.model.attachments[i].h * wr
                  w2 = width
                }*/
        // console.log('atach ',this.model.id, i, this.model.attachments[i].w, this.model.attachments[i].h , "--", rat, w2, h2)
        //if (maxw<w2) maxw = w2
        //if (maxh<h2) maxh = h2

        //}
        //var h=$('#imagerow_' + this.model.id).height()

        if (att_i.playlist){
         // h2 = att_i.h / rat
          var vr = width / att_i.w
          //console.log("H", att_i.w , att_i.h ,   width / att_i.w )
          h2 = (vr * att_i.h )+1



        }






        //console.log('vyslo h2', att_i.h, h2   )
        this.h2 = h2


        var videl= jQuery('#imagerow_' + this.model.id + ' figure video ' )
            videl.attr('width', width)
            videl.css('height', h2+'px')

        jQuery('#imagerow_' + this.model.id + ' figure, #imagerow_' + this.model.id + ' figure .mikroblog-image').css({height: h2, width:width});

        for(var v in this.model.attachments){
          let img = this.model.attachments[v]
          if (img.playlist) {
            this.mounted_video(img)
          }
        }

        this.aktualni = true;

      }
    },

    updateConfirmation: function(status){
      axios.get(process.env.VUE_APP_API_ROOT_URL+'/admin/confirmation/update', {
        params:{ level: status, id: this.model.id} ,
        headers: { 'Authorization': `Bearer ${this.token}`}
      }).then(response => {
        if (response.data.status == 'ok'){
          this.$parent.$parent.refreshMikroblog();
        }
      })
    },
    onWindowResize:function(){
      this.aktualni = false
      this.window_width=window.innerWidth;

    },



    napsat_nabidku:function(){
      window.location.href="/new-offer?to_user_id="+this.model.uid+"&item_id="+this.model.id
    },
    napsat_zpravu:function(){

      window.location.href="/messages/new?to_user_id="+this.model.uid

    },

    mounted_video(img) {
      var width = jQuery('.mikroblog-images').width()

      //let ref = this.$refs[`videoPlayer_${img.id}`][0]

      var vvv= this.window_width && (this.window_width > 500)
      //console.log('video init BYLO', this.model.id, img.id, this.videos[`v${img.id}`])
      if (this.videos[`v${img.id}`] == undefined || this.videos[`v${img.id}`] == null) {
        this.videos[`v${img.id}`] = videojs('hls-player-' + img.id, {
          fluid:true,
          responsive:true,
          height: this.h2,
          width: width,
          controlBar: {
            fullscreenToggle: vvv
          }
        });
      }
      //this.videos[`v${img.id}`].play()
    },


  },
  beforeDestroy() {
    for( var v in this.videos) {
      if (this.videos[v]){
        this.videos[v].dispose()
        this.videos[v]=null
      }
    }
  },

  updated(){
    // console.log('updated mb')
    jQuery('.mikroblog-images').scrollLeft(0)
  }, mounted(){

    console.log('MOUNT item ', this.model.id, this.model.key)

    this.current_user=this.me
    this.onWindowResize()
    //var t= this.model.message
    //var regex = /https:\/\/youtu\.be\/([a-zA-Z0-9]+)/gi;
    //var match = regex.exec(t);
    var th=this
    setTimeout(function(){
      th.aktualisovatVyskuFigure();
    },100)
    //console.log('mounted elemnet itme', this.model.id, this.model.attachments)
  },
  created() {

  },
}


</script>

<style scoped lang="scss">


</style>
