<template>

    <div class="mikroblog-app"  v-touch:swipe="doluu" >
      <div class="mikroblog-item dummy d-none d-sm-block" v-on:click="open_mikroblog_editor()" v-if="enable_editor">
        <div class="msg-header">
          {{ item_type==0 ? $t('b2bein.vlozit-prispevek')  :  $t('b2bein.vloz-poptavku') }}</div>
        <img class="logo d-none d-sm-block" :src="current_logo" />
        <div class="message edit-area" v-if="item_type==0">{{ $t('b2bein.co-je-noveho') }}</div>
        <div class="message edit-area" v-if="item_type==1"></div>
        <div class="tlacitka">

          <a class="btn btn-sm mikroblog-button"><i class="far fa-images"></i> {{ $t('b2bein.Fotka')}}</a>
          <a class="btn btn-sm mikroblog-button"> <i class="fas fa-film mr-auto"></i> {{ $t('b2bein.Video')}}</a>
          <a class="btn btn-sm mikroblog-button"> <i class="fas fa-film mr-auto"></i> {{ $t('b2bein.Text')}}</a>
        </div>
      </div>


     <mikroblog-item v-for="(d, index) in dposts"
                     :key="d.key"
                     :ref="'mb-'+d.id"
                     v-bind:class="'item  it'+d.id+' '+((index+1)==dposts.length ? 'last' : '' )"  :model="d"></mikroblog-item>


      <a v-on:click="open_mikroblog_editor" v-if="enable_editor" class="extrafloat-button extrafloat-button-big  d-sm-none"></a>

      <!-- Modal -->
      <div class="modal fade" id="modal-mikroblog" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ item_type==1 ? $t('b2bein.vloz-poptavku') :  $t('b2bein.Vložit příspěvek') }}
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" v-if="mobil_layout">
              <div class="mb-2">
                <div class="d-flex">
                  <div><img class="logo" :src="current_logo"></div>
                  <div>
                    <div class="mb-1"><strong>{{ current_user.company_name }}</strong></div>
                    <a class="btn btn-sm mikroblog-button" v-on:click="uploadMediaFile"><i class="far fa-images"></i> {{ $t('b2bein.Fotka') }}</a>
                    <a class="btn btn-sm mikroblog-button" v-on:click="uploadMediaFile"> <i class="fas fa-film mr-auto"></i> {{ $t('b2bein.Video')}}</a>
                  </div>
                </div>
              </div>
              <!--9- V řádce, kterou u příspěvků nazýváme, nadpis příspěvku, uděláme dvě buňky.
              První pole umožní volbu příspěvku: Poptávám nebo Nabízím. Slova budu vybírat z rolovacího seznamu.-->



              <input type="text" v-model="title" ra=1 :placeholder="item_type==0 ? $t('b2bein.placeholder.nadpis-prispevku') : $t('b2bein.placeholder.co-poptavate')"  />

              <div v-bind:class="'message edit-area '+classPlaceholderu" @focusout="updateHtml" v-html="msg" contenteditable="true"
                   v-on:click="zrusitPlaceholder"  :placeholder="$t('b2bein.prispevek') +' ...'"></div>


              <div class="mikroblog-images">
                <figure v-for="(img, index) in images" :key="img.id" class="minifigure">
                  <img class='atach' v-bind:src="img.small" />
                  <a @click="removeImage(index)" class="remove-image" :title="$t('b2bein.odstranit_tento_obrazek') "><i class="fas fa-trash-alt "></i></a>
                </figure>
                <div class="clearfix"></div>
              </div>


              <progress-bar bar-color="#dc720f" v-bind:val="uploadPercentage" :text="uploadPercentage+'%'" v-if="uploadPercentage>-1"></progress-bar>
<!--
              <div class="radiogroup">
                {{ $t('b2bein.s-kym-chcete-sdilet') }}
                <select v-model="publicp" class="form-control">
                  <option value="2" >{{ $t('Prezentovat veřejně')}}</option>
                  <option value="1" >{{ $t('prezentovat-partnerum')}} </option>

                </select>

                <select v-model="synctype" class="form-control mt-1">
                  <option value="1" :disabled="current_user && current_user.user_profil.synctype==2">{{ $t('b2bein.prezentovat-eu') }}</option>
                  <option value="2" :disabled="current_user && current_user.user_profil.synctype==1">{{ $t('prezentovat-cn')}} </option>
                  <option value="3" :disabled="current_user && current_user.user_profil.synctype<3">{{ $t('prezentovat-all')}} </option>
                </select>

              </div>
-->


            </div>

            <!-------          velka verze  --------------------------------------  -->

            <div class="modal-body" v-if="!mobil_layout">
              <div class="alert alert-success" v-if="video_upload_ok">{{ $t('b2bein.video_upload_ok') }}</div>
              <div class="d-flex">
                <div class="logocontainer">
                  <img class="logo" :src="current_logo">
                </div>
                <div class="editory">
                  <input type="text" v-model="title"  :placeholder="$t('b2bein.placeholder.nadpis-prispevku')"  />
                  <div v-bind:class="'message edit-area '+classPlaceholderu" @focusout="updateHtml" v-html="msg" contenteditable="true"
                       v-on:click="zrusitPlaceholder"
                       :placeholder1=" $t('b2bein.placeholder.poptavaka-text') "
                       :placeholder0=" $t('b2bein.prispevek') +'...'"></div>
                  <!-- <input type="text" v-model="link" placeholder="{{ $t('b2bein.Odkaz') %>" /> -->
                </div>
              </div>
              <div class="mikroblog-images">
                <figure v-for="(img, index) in images" :key="img.id" class="minifigure">
                  <img class='atach' v-bind:src="img.small" />
                  <a @click="removeImage(index)" class="remove-image" :title="$t('b2bein.odstranit_tento_obrazek')"><i class="fas fa-trash-alt "></i></a>
                </figure>
              </div>
              <progress-bar bar-color="#dc720f" v-bind:val="uploadPercentage" :text="uploadPercentage+'%'" v-if="uploadPercentage>-1"></progress-bar>
            </div>
            <input type="file" id="file" ref="file" v-on:change="onFileChange" style="display: none"/>
            <div v-if="!mobil_layout" class="modal-footer justify-content-between">


              <a class="btn btn-sm mikroblog-button" v-on:click="uploadMediaFile"><i class="far fa-images"></i> {{ $t('b2bein.Fotka') }}</a>
              <a class="btn btn-sm mikroblog-button" v-on:click="uploadMediaFile"> <i class="fas fa-film mr-auto"></i> {{ $t('b2bein.Video') }}</a>
              <!--       {{ $tooltip_help('tip.supported-video-formats') %>
            <a href="#" data-toggle="tooltip" data-html='true' title="<div class='tooltip-num'>#{ tt ? tt.id : ''}</div> #{I18n.t(key)} \" class=\"badge badge-warning badge-pill #{extra_class}\"><b>?</b></a>
     -->


              <div class="ml-auto">&nbsp;
                <a class="btn btn-sm mikroblog-button mazaci-button" v-on:click="destroyPost($t('b2bein.opravdu_odstramit_prispevek'))">
                  <i class="fas fa-trash-alt "></i> {{ $t('b2bein.Odstranit prispevek') }}</a>
              </div>

            </div>
            <div  v-if="!mobil_layout "  class="modal-footer justify-content-between border-0 pt-0">
              <div class="d-flex" v-if="false">
                <div class="col-4 pt-2">{{ $t('b2bein.s-kym-chcete-sdilet') }}</div>
                <select v-model="publicp" class="form-control ">
                  <option value="2" >{{ $t('b2bein.Prezentovat veřejně')}}</option>
                  <option value="1" >{{ $t("prezentovat-partnerum")}}</option>
                  <option value="4" @click="present_togroups()">{{ $t('b2bein.prezentovat-oborove-skupine') }} </option>
                </select>
<!--
                <select v-model="synctype" class="form-control ml-1">
                  <option value="1" <%='disabled' if current_user && current_user.user_profil.synctype==2 %>>{{ $t 'prezentovat-eu' %></option>
                  <option value="2" <%='disabled' if current_user && current_user.user_profil.synctype==1 %>>{{ $t "prezentovat-cn" %> </option>
                  <option value="3" <%='disabled' if current_user && current_user.user_profil.synctype<3 %>>{{ $t "prezentovat-all" %> </option>
                </select>
-->
              </div>







              <button v-on:click="sendMessage" class="btn btn-sm btn-primary ml-auto mikroblog-odeslat col-2">{{ $t('b2bein.Odeslat')}}</button>
            </div>
         <!--   <div class="modal-footer justify-content-between border-0 pt-0" v-if="publicp==3">
              <div class="ml-auto">{{ $t 'Zvolte-partnera'}}
              </div>
              <v-select class='friend-selector' v-bind:items="partners" :options="partners" v-model="sele_friend" label="Select or add Colors" autocomplete @keyup.native.enter="addValue">
                <template slot="option" slot-scope="option">
                  <div class="d-center voption">
                    <img class='logo' :src='option.logo'/>
                    {{ option.company }}<br>           {{ option.email }}
                  </div>
                </template>
                <template slot="selected-option"  slot-scope="option">
                  <div class="selected d-flex d-center">
                    <img class='logo' :src='option.logo'/>
                    <div><b>{{ option.company }}</b><br>       {{ option.email }}</div>
                  </div>
                </template>
              </v-select>
            </div>-->

            <div class="modal-footer"  v-if="mobil_layout">
              <a class="btn btn-sm mikroblog-button mazaci-button" v-on:click="destroyPost($t('b2bein.opravdu_odstramit_prispevek'))">
                <i class="fas fa-trash-alt "></i> {{ $t('b2bein.Odstranit prispevek') }}</a>
              <button v-on:click="sendMessage" class="btn btn-sm btn-primary ">{{ $t('b2bein.Odeslat') }}</button>
            </div>
          </div>
        </div>
      </div>



      <!-- Modal Foto -->
      <div class="modal fade" id="modal-foto" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <img v-bind:src="bigfoto">
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Koment -->
      <div class="modal fade" id="modal-koment" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title">{{ $t('b2bein.vlozit-komentar')}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mb-2">{{ $t('b2bein.koment-k-prslevku')}}: <b>{{komented_item.title}}</b> </div>
              <textarea class="koment-area form-control" v-model="koment" :placeholder="$t('b2bein.komentar')"></textarea>
              <div class="koment-ovladani d-flex mt-3">
                <button v-on:click="sendKoment" class="btn btn-sm btn-primary ml-auto mikroblog-odeslat col-2">{{ $t('b2bein.Odeslat') }}</button>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="modal fade" id="modal-mikroblog-share" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('b2bein.sdilet-s-jednim')}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mb-2">{{ $t('b2bein.s-kym-chcete-sdilet')}} <b>{{komented_item.title}}</b> ?</div>

              <div class="d-flex sdileci-row">
                <div class="mr-2">{{ $t('b2bein.Zvolte-partnera') }} </div>
                <v-select class='friend-selector' v-bind:items="partners" :options="partners" v-model="sele_friend" label="Select or add Colors" autocomplete @keyup.native.enter="addValue">
                  <template slot="option" slot-scope="option">
                    <div class="d-center voption">
                      <img class='logo' :src='option.logo'/>
                      {{ option.company }}<br>           {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-flex d-center">
                      <img class='logo' :src='option.logo'/>
                      <div><b>{{ option.company }}</b><br>       {{ option.email }}</div>
                    </div>
                  </template>
                </v-select>

              </div>

              <div class="koment-ovladani d-flex mt-3">

                <button v-on:click="sdiletSPartnerem()" class="btn btn-sm btn-primary ml-auto mikroblog-odeslat col-2">{{ $t('b2bein.Odeslat') }}</button>
              </div>

            </div>
          </div>
        </div>
      </div>


      <img src="/img/loading2.gif"  id="loading-indicator" v-if="showLoadingIndicator">

    </div>


</template>

<script>


import jQuery from 'jquery'

import Vue from "vue";
import axios from "axios";


import MikroblogItem from "./mikroblog-item"

import vSelect from 'vue-select';

Vue.component('v-select', vSelect)
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)
import ProgressBar from 'vue-simple-progress'
import userMixin from "@/mixins/userMixin";
import axiosApiInstance from "@/utils/api";

export default {
  name: "Mikroblog",
  mixins: [userMixin],

  components:{ MikroblogItem, ProgressBar },
  props: {
    itemtype:{ type: Number, default: 0},

    posts: {},
    partners: {},
    enable_editor: {
      type: Boolean,
      default: true
    },
    konfirmator: {
      type: Boolean,
      default: false
    },
    show_navigator: {
      type: Boolean,
      default: true
    },
  },
  data: function(){
    return {
      showLoadingIndicator:false,
      enable_autoreload:true,

      current_logo:'',
      show_skup_error_message1:false,
      dposts:[],
      msg: '',
      title: '',
      mtarget: null,
      id: '',
      images: [],

      publicp: 2,
      placeholder: '',
      bigfoto: '',
      video_upload_ok: false,
      link: '',

      sele_friend: null,
      komented_item: [],
      koment: '',
      uploadPercentage:-1,
      filter:'all',
      mobil_layout:false,
      obor_groups:[],
      preselected_groups:[],
      selected_groups:[],
      new_post_group:0,
      item_type: 0,
      demand_type:0,
      synctype: window.default_synctype ? window.default_synctype : 1,
      odkazovanyPrispevok:null,
      refrkode:null,
      lastLoadingParams:{
        url:'',
        params: null
      }
    }
  },
  watch: {
    '$route.query.notify_id'() {

      console.log('rout q chang', this.$route)
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      let nid = this.$route.query.notify_id
      axios.get(process.env.VUE_APP_API_ROOT_URL+`/notifier/read?id=${nid}` , config).then(response => {
        console.log('/notifier/read' , response.data)
      })

    }

  },
  computed:{
    current_user(){
      return this.me2
    },

    classPlaceholderu: function (){
      return (this.msg==this.placeholder) ?  'placeh' : '';
    }

  },
  created() {
    window.addEventListener("resize", this.winResizeEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.winResizeEventHandler);
  },

  mounted:function(){
    this.enable_autoreload = true
    this.refrkode = new Date().getTime()

    try {
      var predata = localStorage.getItem('mikroblog-posts')

      this.dposts = JSON.parse(predata)
//    jQuery('.mikroblog-images').scrollLeft(0)
    }catch(ex){
      //this.systemReport(ex, '')
      console.error('no predata')
    }

    let nid = this.$route.query.notify_id
    if (nid){
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axios.get(process.env.VUE_APP_API_ROOT_URL+`/notifier/read?id=${nid}` , config).then(response => {
        console.log('/notifier/read' , response.data)
      })
    }


    let w = document.getElementById('app').clientWidth
    if (w<750)
      this.mobil_layout=true

    if (!this.konfirmator) this.reloadByUrl("/mikroblog/filtr/all")

    this.autorefresh()
    var has = window.location.hash
    if (has){
      var hh = has.replace('#item-','')
      if (hh && hh!=""&& parseInt(hh)>0){
        console.log('presmeruj asi na ', hh, 'pat', this.$route.query) ;
        this.odkazovanyPrispevok=parseInt(hh)

      }

    }

/*
    axios.get('/categories/index').then(response => {
      this.obor_groups = response.data.categories
    })
*/
  },
  methods: {
    winResizeEventHandler(){

      this.aktualizovatVelikosti()

    },
    autorefresh(){
      setTimeout(()=>{
        //console.log('duuu', this.refrkode)
        if (this.konfirmator){
          this.reloadByUrl('/admin/confirmation/load-mikroblogs', { level: this.$parent.mikroblog_level, t: new Date().getTime()})
        }else {
          this.reloadByUrl("/mikroblog/filtr/all")
        }

        if (this.enable_autoreload) this.autorefresh()
      }, 1000*60)
    },


    scrollToSelectedItem(){
      if (this.odkazovanyPrispevok>0){
        //console.log('bude se skrolovat')
        var a = document.getElementsByName(`item-${this.odkazovanyPrispevok}`).item(0)
        //console.log('top', a, a.offsetTop)
        if (a) window.scroll(0,a.offsetTop )

      }
      this.odkazovanyPrispevok=0
    },


  /*  predchoziSkup:function(){
      var sl=$('.obor-posuv-vv').css('left').replace('px', '')
      sl= parseInt(sl)
      if (sl<0)  $('.obor-posuv-vv').animate({'left': (sl+60+14)+'px' }, 200)
    },

    dalsiSkup:function(){
      var sl=$('.obor-posuv-vv').css('left').replace('px', '')
      sl= parseInt(sl)
      $('.obor-posuv-vv').animate({'left': (sl-60-14)+'px' }, 200)
    },
*/
    load_my_groups_if_empty:function(){
      if (this.selected_groups.length<1){
        const config = {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }
        axios.get(process.env.VUE_APP_API_ROOT_URL+'/categories/load_selected', config).then(response => {
          console.log(" loaded selected ", response.data.categories)
          this.selected_groups = response.data.categories ? response.data.categories :[]
          this.preselected_groups = response.data.categories ? response.data.categories :[]
        })
      }
    },

    present_togroups:function(){
      this.load_my_groups_if_empty()
    },

    aktualizovatVelikosti(){

      for (var i in this.$refs){
        if (i.indexOf('mb-')>=0){

          try {
            if (this.$refs[i][0]!=undefined)
              this.$refs[i][0].aktualisovatVyskuFigure();

          }catch(eko){
            console.error(eko)
          }
        }
      }

      for( i in this.dposts){
        this.dposts[i].key ++
      }
    },


    filtrovat:function(filtrTyp){
      this.filter = filtrTyp

      axios.get(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/filtr/'+filtrTyp, {params:{type: this.item_type, fce: 'filtrovat'},  headers: {
          'Authorization': `Bearer ${this.token}`
        }}).then(response => {

        this.dposts = response.data.posts


        this.$nextTick(function(){
          for (var i in this.$refs){
            if (i.indexOf('mb-')>=0){
              try {
                console.log('aktu', this.$refs[i])
                this.$refs[i][0].aktualisovatVyskuFigure();
              }catch(eko){
                console.error(eko)
              }
            }
          }

        })

      })

    },
    doluu: function(){

      //console.log(`mikroblog sqajp doluu`, a, this.lastLoadingParams )
      /*
      if (a=='top'){
        jQuery('.mobile-nav').hide()
      }
      if (a=='bottom'){
        jQuery('.mobile-nav').show()
      }
      */
      if (this.lastLoadingParams.url && this.lastLoadingParams.url!='' ) {

        this.reloadByUrl(this.lastLoadingParams.url, this.lastLoadingParams.params)
      }
    },

    reloadByUrl: function(url, _params){

      this.lastLoadingParams = {url: url, params: _params}

      if (!_params) _params ={fce: 'reloadByUrl'}
      _params.type = this.item_type
      var that = this


      this.showLoadingIndicator=true

      axiosApiInstance.get( url, { params: _params, headers: {
          'Authorization': `Bearer ${this.token}`
        }}).then(response => {
            var d = response.data
            //document.getElementById('col4book').style.background='#aaaaaa'
            //console.log('nastav adata ponavratu')
            this.showLoadingIndicator=false
            for (var i = 0; i < d.posts.length; i++) {
                d.posts[i].key = d.posts[i].id * 1000
            }
            that.dposts =  d.posts
            //document.getElementById('col4book').style.background=''

            //this.posts = d.posts
            try {
              var predata = JSON.stringify(d.posts)
              localStorage.setItem('mikroblog-posts', predata)
            }catch(e){
              console.log('chyba localStorage mikroblog', e)
            }
            //console.log('mazat ',that.dposts )
            //that.dposts.splice(0, that.dposts.length)
            //for (var i = 0; i < d.posts.length; i++) {
            //  this.$set(that.dposts, i, d.posts[i])
            //}

            this.aktualizovatVelikosti()


            this.scrollToSelectedItem()

            this.updateLastReadedItem()

          }).catch((e)=>{

            if (e.response.status==403 && e.response.data.code==40){
              this.$router.push('/main/non-confirmed')
              return
            }
            if (e.response.status==401){
              this.enable_autoreload = false
            }
            console.log('chyba mikroblog 1', e)
          }) ;
    },

    updateLastReadedItem(){
      if (!document.getElementById('col4book')) return
      try {
        let w = document.getElementById('app').clientWidth
        let w2 = document.getElementById('col4book').clientWidth
        if (w>10){
            axiosApiInstance.get( `/mikroblog/update_read_at?w=${w2}`, { headers: { 'Authorization': `Bearer ${this.token}` }})
        }
      }catch (e) {
          console.log('chyba mikroblog 2', e)
      }
    },

    sdiletSPartnerem:function(){
      this._sendShare( this.komented_item, 3, this.sele_friend.id )
    },

    _sendShare:function(kitem, level, user_id){
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axios.post(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/share', {mikroblog: kitem.id, level: level, user_id: user_id}, config)
          .then(response => {
            var d = response.data
            console.log('sdilel', d, d.item)
            jQuery('#modal-mikroblog-share').modal('hide');
            kitem.alert=d.msg
            kitem.my_sharing_count++

          });
    },
    sendKoment:function(){
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axios.post(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/koment', {koment: this.koment, item_id: this.komented_item.id }, config)
          .then(response => {
            //var d = response.data
            jQuery('#modal-koment').modal('hide');
            console.log('resp', response.data)

            this.koment = ''
            this.komented_item.comments = response.data

          });
    },
    showKomentDialog:function(model){
      jQuery('#modal-koment').modal({});
      this.komented_item=model

    },

    showLightbox:function(images, index){
      //console.log('index', index, images)
      this.bigfoto = images[index].big
      jQuery('#modal-foto').modal({});


    },

    updateHtml:function (e) {
      this.msg=e.target.innerHTML
      this.mtarget=e.target
    },

    sendMessage: function () {

      if (this.uploadPercentage>=0) {
        alert('action disabled, upload in progress');
        return
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }

      //console.log("MSG sedn", this.msg, this.me.b2_token)

      axios.post(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/create', {
        msg: this.msg,
        title: this.title,
        id: this.id,
        pub: this.publicp,
        link: this.link,
        group: this.new_post_group,
        to_user_id: this.sele_friend ? this.sele_friend.id : null,
        item_type: this.item_type,
        synctype: this.synctype
      }, config)
          .then(response => {
            var d = response.data

            this.dposts = d.posts
//            this.dposts.splice(0, this.dposts.length)
    /*        for(var i=0 ; i< d.posts.length; i++){
              this.$set(this.dposts, i, d.posts[i])
            }
*/
            var p = response.data.post
            this.id=p.id
            //console.log('potom1', d.posts)
            jQuery('#modal-mikroblog').modal('hide');
          });
    },

    uploadMediaFile:function(){
      document.getElementById('file').click();
    },
    onFileChange:function(){
      this.file = this.$refs.file.files[0];
      var formData = new FormData();
      formData.append('file', this.file);

      formData.append('msg', this.msg);
      formData.append('title', this.title);
      formData.append('id', this.id);
      formData.append('pub', this.publicp);
      formData.append('link', this.link);



      axios.post(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',  'Authorization': `Bearer ${this.token}`
            },
            onUploadProgress: function( progressEvent ) {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }.bind(this)
          }
      ).then(response => {
        console.log('SUCCESS!!', response.data);
        if (response.data.status==1) {
          var p = response.data.post
          this.id = p.id
          this.images = p.attachments
          this.video_upload_ok = response.data.video_upload_ok
        }else{
          alert(response.data.error)
        }
        this.uploadPercentage=-1
      }).catch( (err) => {
        console.log('FAILURE!!');
        alert('upload failure '+err.toString())
        this.uploadPercentage=-1
      });

    },

    zrusitPlaceholder:function(){

      if (this.msg==this.placeholder){
        this.msg=''
      }
    },

    open_mikroblog_editor:function( ){
      this.uploadPercentage = -1

      let w = document.getElementById('app').clientWidth

      if (w<500)
        this.mobil_layout=true
      else
        this.mobil_layout=false


      //console.log(" moba la", this.mobil_layout)

      this.video_upload_ok=false
      if (this.current_user){
        this.placeholder=jQuery('#modal-mikroblog .edit-area').attr('placeholder'+this.item_type)
        //console.log('placak',this.placeholder , jQuery('#modal-mikroblog .edit-area'))
        this.id=null
        this.images=[]
        this.msg=this.placeholder
        this.title=""
        this.publicp=2
        this.link=''

        jQuery('#modal-mikroblog').modal({  });
      }else {
        console.log('mozna neni prihlas', this.current_user, this.token)
       // window.location.href='/users/sign_in'
      }
    },
    removeImage:function(index){
      // console.log('rm img', index,  this.images[index])

      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axios.get(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/destroy-attachment/'+this.images[index].id, config)
          .then(response => {
            console.log('SUCCESS!!', response.data);
            var d = response.data
            if (d.status =='ok'){
              for (var i in this.posts){
                if (this.posts[i].id == this.id){
                  this.posts[i].attachments.splice(index, 1)
                }
              }
              this.images.splice(index, 1);
            }
          })
          .catch(function () {
            console.log('FAILURE!!');
          });
    },
    destroyPost: function(confirmMessahge){
      if (confirm(confirmMessahge)){
        const config = {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }
        axios.get(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/destroy/'+this.id, config)
            .then(response => {
              console.log('SUCCESS!!', response.data);
              var d = response.data
              this.dposts = d.posts
              this.id=null
              this.images=[]
              this.msg=this.placeholder
              this.title=""
              this.publicp=2

              jQuery('#modal-mikroblog').modal('hide');

            })
            .catch(function () {
              console.log('FAILURE!!');
            });

      }
    },


    edt:function(E){

      axios.get(process.env.VUE_APP_API_ROOT_URL+'/mikroblog/edit/'+E, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          }
      ).then(response => {
        console.log('SUCCESS!!', response.data);
        if (response.data.status==1){
          var p = response.data.post
          this.id=p.id
          this.images=p.attachments
          this.msg=p.message
          this.title=p.title
          this.publicp=p.pub
          this.link=p.link
          jQuery('#modal-mikroblog').modal({

          });
        }

      })
          .catch(function () {
            console.log('FAILURE!!');
          });
    },

    addGroupDialog: function(){
      jQuery('#modal-addgroup').modal({});
      if (this.obor_groups.length<1) {
        const config = {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }
        axios.get(process.env.VUE_APP_API_ROOT_URL+'/categories/index', config).then(response => {
          this.obor_groups = response.data.categories
        })
      }
    },
    selectCat: function(cat){

      console.log("klik", this.preselected_groups)
      for (var i=0; i<this.preselected_groups.length; i++){
        if (this.preselected_groups[i].id == cat.id) {
          this.preselected_groups.splice(i,1)
          return
        }
      }
      this.preselected_groups.push(cat)
      this.show_skup_error_message1=false;
      /* */
    },

    cat_selected:function(cat){
      for (var i in this.preselected_groups){
        if (this.preselected_groups[i].id == cat.id) return true;
      }
      return false;
    },

    cat_semisel:function(cat){

      if (!cat.categories) return false;

      for (var i in cat.categories){
        if (this.cat_selected(cat.categories[i])) return true;
        if (this.cat_semisel(cat.categories[i])) return true;
      }
    },

    confirmOborSkup:function(){
      if (this.preselected_groups.length<1) {
        this.show_skup_error_message1=true;
        return
      }


      this.selected_groups = this.preselected_groups
      jQuery('#modal-addgroup').modal('hide');
      var ids=[]
      for (var i in this.selected_groups){
        ids.push(this.selected_groups[i].id)
      }
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axios.post(process.env.VUE_APP_API_ROOT_URL+'/categories/update-selected-groups', {group_ids: ids}, config).then(() => {

      })
    }

  },

}



</script>

<style scoped>
#loading-indicator{
  position: absolute;
  top:0;
  right:0;
  width: 40px;
}

.minifigure{
  text-align: center;
}
</style>
